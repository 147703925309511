.ui.menu.topMenu {
  font-size: large;
  background-color: var(--theme-light-color-1);
  border-radius: 0;
}

.ui.menu .header.item {
  padding-left: 0;
}

.ui.menu.topMenu a.active .item:not(.header) {
  color: white;
  background-color: var(--theme-main-color);
}
