body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --theme-main-color: #016fd0;
  --theme-light-color-1: #82b6e8;
  --theme-light-color-2: rgb(235, 239, 248);
  --theme-hover-color: #004480;
  --theme-alternative-font-color: rgb(235, 239, 248);
  --success-color: #5aa856;
  --warning-color: #ffc107;
  --error-color: #993333;
  --theme-pricerange-color: #016fd0;
  --theme-h2-color: black;
}
