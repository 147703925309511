div.loginPage {
  display: grid;
  height: calc(100vh - 80px);
  justify-content: center;
  align-content: center;
  grid-template-columns: minmax(150px, 350px);
}

div.loginPage > div {
  margin-top: -80px !important;
}
