.str-chat.messaging {
  background-color: unset;
  height: 85vh;
  overflow-y: scroll;
}

.str-chat__main-panel,
.str-chat.messaging.str-chat-channel-list,
.str-chat__channel-list-messenger {
  background-color: var(--theme-light-color-2);
  border-radius: 0.28571429rem;
}

.str-chat__header-livestream {
  min-height: 0;
}

.str-chat__typing-indicator:not(.str-chat__typing-indicator--typing)
  .str-chat__typing-indicator__dots {
  height: 0;
}
